<template>
  <div class="container">
    <TopHeader title="访客邀请"></TopHeader>
    <div class="content">
      <van-form class="form-content">
        <van-field
          v-model="visit.phone"
          name="接收手机号"
          input-align="right"
          required
          label="接收手机号"
          placeholder="请输入手机号"
        />
        <CommonBorder></CommonBorder>
        <van-field
          v-model="userName"
          name="一级审核"
          input-align="right"
          required
          readonly
          label="一级审核"
          placeholder="一级审核"
        />
        <CommonBorder></CommonBorder>
        <div class="form-items">
          <div class="form-items-title">二级审核</div>
          <div class="form-items-content" v-if="slecteName" style="color: #333;" @click="show = true">{{ slecteName }}</div>
          <div class="form-items-content" v-if="!slecteName" style="color: #C4C4C4;" @click="show = true">请选择<img class="bottomImg" :src="bottomImg" alt=""></div>
        </div>
      </van-form>
      <div class="btn-content">
        <van-button class="btn" block @click="addVisitFn">发送短信</van-button>
      </div>
    </div>
    <van-popup v-model="show" position="bottom">
      <van-picker
        show-toolbar 
        confirm-button-text="确定"
        :columns="departmentManager"
        value-key="managerName"
        @confirm="departmentConfirm"
        @cancel="show = false"
      />
    </van-popup>
  </div>
</template>

<script>
import cancel from "@/assets/cancel.png";
import { visitAddAPI, getDepartmentListAPI } from "@/api/invite";
import { Toast } from "vant";
import TopHeader from '@/components/topHeader/index.vue'
import CommonBorder from '@/components/commonBorder/index.vue'
import bottomImg from '@/assets/images/bottomImg.png'
export default {
  name: "InviteAdd",
  components: {
    TopHeader,
    CommonBorder
  },
  data() {
    return {
      cancel,
      bottomImg,
      show: false,
      consumeList: [],
      visit: {
        phone: "",
        verify1Id: "",
        verify2Id: "",
      },
      userName: "",
      apiUrl: process.env.VUE_APP_BASE_API,
      departmentManager: [],
      organizationId: "",
      isSubmit: false,
      slecteName: "",
    };
  },
  created() {
    const user = JSON.parse(sessionStorage.getItem("personInfo"));
    if (user.departmentManager) {
      this.departmentManager.push(user.departmentManager);
    }
    this.organizationId = user.organizationId;
    this.userName = user.name;
    this.visit.verify1Id = user.id;
    this.visit.url = window.location.origin + `/inviteQuery?url=`;
    this.getdepartmentManagerFn();
  },
  methods: {
    async addVisitFn() {
      if (!this.isSubmit) {
        if (
          this.visit.phone.trim() === "" ||
          this.visit.phone.trim().length !== 11
        ) {
          Toast({
            message: "手机号不能为空或手机号长度不为11位!",
          });
          return;
        }
        const res = await visitAddAPI({ visit: this.visit })
        if (res.code === 0) {
          Toast.success({
            message: "短信已发送!",
          });
          this.isSubmit = true;
        }
      } else {
        Toast.fail({
          message: "短信已发送,不能重复发送!",
        });
      }
    },
    departmentConfirm(val) {
      if (val) {
        this.show = false;
        this.visit.verify2Id = val.manager;
        this.slecteName = val.managerName;
      } else {
        this.show = false;
      }
    },
    async getdepartmentManagerFn() {
      const res = await getDepartmentListAPI("", this.organizationId)
      if (res.code === 0) {
        const userId = JSON.parse(sessionStorage.getItem("personInfo")).id;
        this.departmentManager = res.data;
        this.departmentManager.map((item, index) => {
          if (item.manager === userId) {
            this.departmentManager.splice(index, 1);
          }
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .content{
    flex:1;
    margin-top: 10px;
    .form-content{
      background: #fff;
      box-sizing: border-box;
      padding-left: 4px;
      .form-items{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 45px;
        box-sizing: border-box;
        padding: 0 16px;
        .form-items-title{
          font-size: 14px;
          color: #646566;
          flex: 1;
        }
        .form-items-content{
          width: 100px;
          font-size: 15px;
          display: flex;
          flex-direction: row;
          align-items: end;
          justify-content: flex-end;
        }
      }
    }
    .btn-content{
      margin: 30px 16px;
      .btn{
        background: #FA5050;
        color: #fff;
        border-color: #FA5050;
        font-size: 18px;
      }
    }
  }
}

::v-deep .el-input__inner {
  border: none;
}
.bottomImg{
  width: 19px;
  height: 19px;
}
::v-deep .van-picker__toolbar{
  background: #f1f1f1;
  height: 50px;
}
::v-deep .van-picker__cancel{
  font-size: 16px;
  color: #666;
}
::v-deep .van-picker__confirm{
  font-size: 16px;
  color: #ffa900;
}
::v-deep .van-ellipsis{
  color: #333;
} 
</style>
